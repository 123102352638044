import React from 'react';
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom';
import Redirector from './Redirector';
import './App.css';

function App() {
  // I'm not even sure I need a router, but let's say we've prepared for future links
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route path="*" component={Redirector} exact />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
