import React, {Component} from 'react';
import QRCode from 'qrcode.react';
import Logo from './logo.svg';
import AppStoreIcon from './appStore.svg';
import GooglePlayIcon from './googlePlay.svg';

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }
  return 'unknown';
}

class Redirector extends Component {
  render() {
    let message;
    const operatingSystem = getMobileOperatingSystem();

    if (operatingSystem !== 'unknown') {
      message = 'Download the app and join the community!';
    } else {
      message = (
        <>
          {
            'This content is not available on desktop. Scan the QR code below with your mobile device or '
          }
          <a href={'https://arya.xyz'}>try our WebApp</a>.
        </>
      );
    }

    return (
      <>
        <img src={Logo} className={'logo'} alt="ARYA" />
        <p className={'intro'}>{message}</p>
        {operatingSystem === 'iOS' && (
          <div className="storeLinks">
            <a
              href={
                'https://apps.apple.com/app/arya-invest-smart-live-better/id1478620685'
              }>
              <img
                src={AppStoreIcon}
                className={'storeLogo'}
                alt={'App Store'}
              />
            </a>
          </div>
        )}
        {operatingSystem === 'Android' && (
          <div className="storeLinks">
            <a href={'market://details?id=com.techx.arya'}>
              <img
                src={GooglePlayIcon}
                className={'storeLogo'}
                alt={'Google Play'}
              />
            </a>
          </div>
        )}
        {operatingSystem === 'unknown' && (
          <>
            <div className={'qrcode'}>
              <QRCode value={window.location.toString()} />
            </div>
            <div className="storeLinks">
              <a
                href={
                  'https://apps.apple.com/app/arya-invest-smart-live-better/id1478620685'
                }>
                <img
                  src={AppStoreIcon}
                  className={'storeLogo'}
                  alt={'App Store'}
                />
              </a>
              <a
                href={
                  'https://play.google.com/store/apps/details?id=com.techx.arya'
                }>
                <img
                  src={GooglePlayIcon}
                  className={'storeLogo'}
                  alt={'Google Play'}
                />
              </a>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Redirector;
